import React from "react"
import Signup from "./Signup";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Settings from "./Settings"
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import VerifyEmail from "./VerifyEmail";
import Home from "./Home";
import TopMenuBar from "./TopMenuBar";
import Logout from "./Logout";
import UserHome from "./user/UserHome";
import UserSuggest from "./user/UserSuggest";
import Suggest from "./Suggest";
import UserAddRecipe from "./user/UserAddRecipe";
import UserRecipes from "./user/UserRecipes";

function App() {
  return (
      <Container className="d-flex justify-content-center" style={{ minHeight: "100vh" }}>
        <div className="w-100" style={{ maxWidth: "600px" }}>
          <Router>
            <AuthProvider>
              <TopMenuBar />              
              <Routes>     
                <Route index element={<Home />} />   
                <Route path='/home' index element={<Home />} />  
                <Route path="/suggest" element={<Suggest />} />                     
                <Route path='/user/suggest' element={<PrivateRoute redirectPath='/suggest'/>}>
                  <Route path='/user/suggest' index element={<UserSuggest />} />  
                </Route>                                             
                <Route path='/user/' element={<PrivateRoute redirectPath='/verify-email'/>}>
                  <Route path='/user/home' index element={<UserHome />} />  
                </Route>     
                <Route path='/user/add-recipe' element={<PrivateRoute redirectPath='/verify-email'/>}>
                  <Route path='/user/add-recipe' index element={<UserAddRecipe />} />  
                </Route>    
                <Route path='/user/recipes' element={<PrivateRoute redirectPath='/verify-email'/>}>
                  <Route path='/user/recipes' index element={<UserRecipes />} />  
                </Route>   
                <Route path='/verify-email' element={<PrivateRoute/>}>                
                  <Route path='/verify-email' element={<VerifyEmail/>}/>               
                </Route>                                                              
                <Route path='/settings' element={<PrivateRoute/>}>                
                  <Route path='/settings' element={<Settings/>}/>               
                </Route>  
                <Route path='/update-profile' element={<PrivateRoute/>}>                
                  <Route path='/update-profile' element={<UpdateProfile/>}/>                  
                </Route>                                              
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/logout" element={<Logout />} />                
              </Routes>
            </AuthProvider>
          </Router>
        </div>
      </Container>
  );
}

export default App;
