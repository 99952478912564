import React, { useState } from 'react'
import { Container, Navbar, Nav, NavDropdown, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext"

export default function TopMenuBar() {
    const NavigationDefault = () => (
        <Navbar collapseOnSelect expand="lg" bg="success" variant="dark" className="mb-3">
          <Container className="mx-4" >
            <Navbar.Brand href="/home">What Meal Today</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <Nav.Link href="/suggest">Suggest</Nav.Link>       
                <Button className="me-2" href="signup" variant="outline-light">Sign Up</Button>
                <Button href="login" className="btn btn-light">Login</Button> 
              </Nav>          
            </Navbar.Collapse>
          </Container>
        </Navbar>
    )
    
    const NavigationLoggedIn = () => (
      <Navbar collapseOnSelect expand="lg" bg="success" variant="dark" className="mb-3">
        <Container className="mx-4" >
          <Navbar.Brand href="/home">What Meal Today</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Nav.Link href="/user/suggest">Suggest</Nav.Link>       
              <NavDropdown title="My Profile" id="basic-nav-dropdown" className="me-2">
                <NavDropdown.Item href="/user/add-recipe">Add Recipe</NavDropdown.Item>
                <NavDropdown.Item href="/user/recipes">My Recipes</NavDropdown.Item>                
                <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                <NavDropdown.Item href="/update-profile">Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/logout" onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>          
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()  

    async function handleLogout() {         
        setError('')

        try {
            await logout()        
        } catch {
            setError('Failed to logout')
        }
    }    

  return (
    <div>
      {currentUser ? <NavigationLoggedIn /> : <NavigationDefault />}
      {error && <Alert variant="danger">{error}</Alert>}   
    </div>
  )
}
