import React, { useState } from 'react'
import { Form, Button, Container, Alert } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"

export default function VerifyEmail() {
  const { currentUser, ResendVerificationEmail } = useAuth()
  const [error, setError] = useState('')
  const [msg, setMsg] = useState('')  
  const [loading, setLoading] = useState(false)

  const EmailIsNotVerified = () => (
    <Container>
      {error && <Alert variant="danger">{error}</Alert>}
      <p>To access all the features on this website, your email needs to be verified. </p> 
      <p><strong>Email: </strong>{currentUser.email} {currentUser.emailVerified ? <span className="text-success">Verified</span> : <span className="text-danger">Not Verified</span>}</p>

      <p>Check your inbox and click on the link in the email to verify your email address.</p>
      <p><strong>Important! If you have not received verification email, please check your spam folder.</strong></p>
      <Form onSubmit={handleSubmit}>
        <Button disabled={loading} className="w-100 mt-3" type="submit">Re-Send Verification Email</Button>       
      </Form>
      {msg && <Alert variant="success" className="mt-3">Verification email sent out successfuly. Please check your inbox, click on the verification link and <Alert.Link href="/verify-email">refresh this page</Alert.Link>.</Alert>}      

    </Container>
  )  

  const EmailIsVerified = () => (
    <Container>
      {error && <Alert variant="danger">{error}</Alert>}
      
      <p><strong>Email: </strong>{currentUser.email} {currentUser.emailVerified ? <span className="text-success">Verified</span> : <span className="text-danger">Not Verified</span>}</p>

      <p>Your email is verified.</p>

    </Container>
  )    

  async function handleSubmit(e) {
    e.preventDefault()

    try {
        setError('')
        setMsg('')
        setLoading(true)
        await ResendVerificationEmail()  
        setMsg('Success')       
    } catch {
        setError('Failed to send out verification email')
    }
    setLoading(false)

}

  return (
    <>
      {currentUser.emailVerified ? <EmailIsVerified /> : <EmailIsNotVerified />}
    </>
  )
}
