import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "firebase/auth"
import React, { useContext, useEffect, useState } from 'react'
import { auth } from "../firebase"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    async function signup(email, password) {
        /*const newUser = createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log("sign up sucessfully")
                
                // Send out verification email
                sendEmailVerification(user)
                    .then(() => {
                    // Email sent
                    // ...
                    console.log("verification email sent out sucessfully")            
                    })
                    .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ..
                    console.log("email verification error")            
                    throw "Email verification Error"            
                    });       
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log("sign up error")            
                throw "Sign Up Error"            
            });
        return newUser*/

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // Signed in 
            const user = userCredential.user;
            console.log("sign up sucessfully")
            
            // Send out verification email
            sendEmailVerification(user)
                .then(() => {
                // Email sent
                // ...
                console.log("verification email sent out sucessfully")            
                })
                .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log("email verification error")            
                throw "Email verification Error"            
            });  
            return user;
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("sign up error");
            throw "Sign Up Error";
        }
    }

    function ResendVerificationEmail() {
        // Send out verification email
        sendEmailVerification(auth.currentUser)
            .then(() => {
                // Email sent
                // ...
                console.log("verification email re-sent out sucessfully")            
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log("email verification re-send error")            
                throw "Email verification Error"            
            });  
    }     

    function login(email, password) {
        const loginUser = signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("login sucessfully")
            // ...
            })
            .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
            console.log("login error")
            console.log(error)
            throw "Login Error"
            });
        return loginUser
    }    

    function logout(email, password) {
        const logoutUser = signOut(auth)
            .then(() => {
                // logout
                console.log("logout sucessfully")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log("logout error")
                console.log(error)
                throw "Logout Error"
            });
        return logoutUser
    }      

    function resetPassword(email) {
        const logoutUser = sendPasswordResetEmail(auth, email)
            .then((userCredential) => {
            // Signed in 
            console.log("password reset sucessful")
            // ...
            })
            .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
            console.log("password reset error")
            console.log(error)
            throw "Password Reset Error"
            });
        return logoutUser
    }        

    function updateUserEmail(email) {
        updateEmail(auth.currentUser, email).then(() => {
            // Email updated!
            // ...
            console.log("Email updated")            
          }).catch((error) => {
            // An error occurred
            // ...
            console.log("Email update error")
            console.log(error)
            throw "Email update Error"            
          });
    }   
    
    function updateUserPassword(password) {
        updatePassword(auth.currentUser, password).then(() => {
            // Update successful.
            console.log("password updated")            
          }).catch((error) => {
            // An error ocurred
            // ...
            console.log("password update error")
            console.log(error)
            throw "Password Update Error"            
          });
    }      

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // ...
                if (user.emailVerified) {
                    setCurrentUser(user)
                    console.log("signed in")                
                }
                else
                    console.log ("email not verified")
            } else {
            // User is signed out
            // ...
            console.log("signed out")
            }
        });
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signup,
        ResendVerificationEmail,
        login,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword
    }

    return (
        <AuthContext.Provider value = {value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
