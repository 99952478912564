import React, { useState } from 'react'
import { Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from 'react-router-dom'

export default function Settings() {
    const { currentUser, logout } = useAuth()

    return (
        <>
        <h2 className="mb-4">Settings</h2>
        <div className="d-flex justify-content-start">
            <div className="me-2">
                <h4 className="mb-2">My account</h4>
            </div>
            <div>
                <Link to="/update-profile">Edit</Link>
            </div>
        </div>
        <strong>Email: </strong>{currentUser.email}
        { (!currentUser.emailVerified) && <div className="text-danger">Your email is not verified, please <Link to="/verify-email">verify it here</Link></div>} 
        </>
    )
}
