import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap"
import { db, storage } from "../../firebase";
import { doc, collection, addDoc, getDoc, deleteDoc, serverTimestamp, updateDoc, CACHE_SIZE_UNLIMITED, arrayUnion } from "firebase/firestore"
import { useAuth } from "../../contexts/AuthContext"

import { getRecipeTextData } from './HelperFunctions';

export const ExpandedRecipeCard = ({ recipe, recipeId, language, onClose }) => {

    const titleRef = useRef()
    const recipeRef = useRef()
    const urlRef = useRef()    
    const mynotesRef = useRef()    
    const visibilityRef = useRef()

    const { currentUser } = useAuth();

    console.log(recipe.sharedWith);

    async function handleDelete(event){
        event.preventDefault();

        const recipeRefDoc = doc(db, "recipes", recipeId);
        await deleteDoc(recipeRefDoc);

        // reload the page to rerender the react components
        window.location.reload();
    }

    async function handleSubmit(event){
        // for some reason we have to disable the native form behaviour, or else firebase will send an error
        // VERY IMPORTANT LINE DO NOT DELETE because the afformentioned error is very vague
        event.preventDefault();

        console.log("Handling submit");
        // create reference to recipe
        const recipeRefDoc = doc(db, "recipes", recipeId);
        
        // update recipe with all new values. When updating languages, remember to update all children
        // because you will delete the other children if you don't update them, which leads to an error

        // create visibility variable based on visibilityRef
        console.log(visibilityRef.current.value);
        var visibilityValue = visibilityRef.current.value == "public" ? ["*"] : [currentUser.uid];

        await updateDoc(recipeRefDoc, {
            languages: {
                lg_en: {
                    exists: true,
                    title: titleRef.current.value,
                    recipe: recipeRef.current.value,
                    linkUrl: urlRef.current.value,
                    createDate: serverTimestamp()
                }
            },
            myNotes: mynotesRef.current.value,
            sharedWith: visibilityValue
        });

        // reload the page to rerender the react components
        window.location.reload();
    }

    // Render the details of the recipe in a larger card
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group id="title" className="mt-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control type="text" size="lg" defaultValue={getRecipeTextData(recipe, language).title} ref={titleRef} required />
            </Form.Group>
            <Form.Group id="recipe" className="mt-3">
                <Form.Label>Recipe:</Form.Label>
                <Form.Control as="textarea" defaultValue={getRecipeTextData(recipe, language).recipe} ref={recipeRef} rows={5} />
            </Form.Group>  
            <Form.Group id="url" className="mt-3">
                <Form.Label>Website URL:</Form.Label>
                <Form.Control type="text" ref={urlRef} defaultValue={getRecipeTextData(recipe, language).linkUrl} />
            </Form.Group>                        
            <hr className="mt-5" />
            <Form.Group id="mynotes" className="mt-3">
                <Form.Label>My notes:</Form.Label>
                <Form.Control as="textarea" defaultValue={recipe.myNotes} ref={mynotesRef} rows={3} />
            </Form.Group>      
            <Form.Group id="visibility" className="mt-3">    
                <Form.Label>Visibility:</Form.Label>
                <Form.Select ref={visibilityRef} defaultValue={recipe.sharedWith.includes("*") ? "public" : "private"}>
                    <option value="public">Public - Everyone can see this recipe</option>
                    <option value="private">Private - Only I can see this recipe</option>
                </Form.Select>   
            </Form.Group>       
            <Button className="mt-3 m-2 btn-danger" style={{width: 45 + '%'}} type="button" onClick={handleDelete}>Delete</Button>
            <Button className="mt-3 m-2" style={{width: 45 + '%'}} type="submit">Save</Button>
            <Button className="mt-1 m-2" style={{width: 95 + '%'}} type="button" onClick={onClose}>Close</Button>
        </Form>
    );
}