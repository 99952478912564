import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button, Form, Alert } from "react-bootstrap"
import { ChevronCompactLeft, ChevronCompactRight } from 'react-bootstrap-icons';
import test_image from "../../assets/600x600.jpg";
import { Link, useNavigate } from 'react-router-dom'
import { db, storage } from "../../firebase";
import { doc, runTransaction, collection, addDoc, serverTimestamp, updateDoc, CACHE_SIZE_UNLIMITED, arrayUnion } from "firebase/firestore"
import { ref, uploadBytes, getDownloadURL,uploadBytesResumable } from "firebase/storage"
import { useAuth } from "../../contexts/AuthContext"
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { getUserInfo } from './HelperFunctions';

export default function UserAddRecipe() {
    const { currentUser } = useAuth()
    const titleRef = useRef()
    const recipeRef = useRef()
    const urlRef = useRef()    
    const mynotesRef = useRef()    
    const visibilityRef = useRef()    
    const languageRef = useRef()    
    const fileRef = useRef()       
    
    const [userNickname, setUserNickname] = useState("anonymous");

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false) 
    const history = useNavigate()

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null)
    const canvasRef = useRef(null);
    const [crop, setCrop] = useState({unit: "px", x:0, y: 0, width: 100, height: 100});
    const croppedImage = useRef(null);    

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);        
          const file = e.target.files[0]
        }
      };
    
    const onImageLoad = (img) => {
        const imagetest = new Image();
        imagetest.src = img.target.src
        imgRef.current = imagetest

        onCropComplete(crop)   
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };  
    
    const makeClientCrop = async (crop) => {
            if (imgRef.current && crop.width && crop.height) {
            croppedImage.current = await getCroppedImg(
                imgRef.current,
                crop,
                "newFile.jpeg"
            );
            var file = new File([croppedImage.current], "name");
            }
        };
        
    const getCroppedImg = (image, crop, fileName) => {

        if (!canvasRef.current || !imgRef.current) {
            return;
        }

        const canvas = canvasRef.current;
        const pixelRatio = window.devicePixelRatio;
        //const scaleX = image.naturalWidth / image.width;
        //const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        var cropImageContainer = document.getElementById("cropimage");

        const scaleX = image.naturalWidth / cropImageContainer.offsetWidth;
        const scaleY = image.naturalHeight / cropImageContainer.offsetHeight;      

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;               

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error("Canvas is empty");
                return;
            }
            blob.name = fileName;
            resolve(blob);
            }, "image/jpeg");
        });
    };
    

    function handleUpload(e, docRef) {
        //e.preventDefault() //don't refresh

        //console.log('Upload ....')
        //console.log(fileRef.current.files[0])
        const origFile = fileRef.current.files[0]
        //console.log(origFile);

        var file = new File([croppedImage.current], origFile.name);
        //console.log(file)        

        if (file == null) return

        const storageRef = ref(storage, `/files/${currentUser.uid}/${docRef.id}/${file.name}`)

        const metadata = {
            contentType: file.type,
          };

        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                //console.log('Upload is paused');
                break;
            case 'running':
                //console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {              
                console.log('File available at', downloadURL);
                updateDoc(docRef, {  
                        languages: {
                            [languageRef.current.value]: {
                                exists: true,
                                title: titleRef.current.value,
                                recipe: recipeRef.current.value,
                                linkUrl: urlRef.current.value, 
                                createDate: serverTimestamp()
                            }                          
                        },
                        thumbnailUrl: downloadURL
                }).then(() => {
                    setLoading(false)
                    console.log("Recipe updated: ", docRef.id); 
                    history("/user/recipes");  
                })  
            });
        }
        );             
    }

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)

            // create visibility variable based on visibilityRef
            var visibilityValue = visibilityRef.current.value == "public" ? ["*"] : [currentUser.uid];

            // when creating a new recipe we have to have a transaction with the counter
            // so that we increment the counter and give the new recipe a 'randomValue' equal to that counter

            const counterDocRef = doc(db, "counters", "recipe_counter");

            try {
                const newCounterVal = await runTransaction(db, async (transaction) => {
                    const counterDoc = await transaction.get(counterDocRef);
                    // following should never happen
                    if (!counterDoc.exists()) {
                        throw "Counter doc does not exist!";
                    }

                    const newVal = counterDoc.data().value + 1;

                    transaction.update(counterDocRef, { value: newVal });
                    return newVal;
                });

                console.log("Recipe value increased to: ", newCounterVal);

                const docRef = await addDoc(collection(db, "recipes"), {                
                    userId: currentUser.uid,
                    userNickname: userNickname,
                    myNotes: mynotesRef.current.value,
                    sharedWith: visibilityValue,
                    likedBy: [],
                    lastUpdated: serverTimestamp(),  
                    languages: {},
                    randomValue: newCounterVal
                });        
                //console.log("Recipe created with ID: ", docRef.id);
                handleUpload(e,docRef)

            } catch (e) {
                // This will be a "population is too big" error.
                console.error(e);
            }
        } catch (e) {
            setError('Failed to add this recipe')
            console.error("Error creating recipe: ", e);
        }
    }  

    useEffect(() => {
        async function fetchData() {
            try {
                const userData = await getUserInfo(currentUser.uid);
                console.log(userData.userNickname);
                setUserNickname(userData.userNickname);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

  return (
    <Container className="mb-5">
        <Row className="row align-items-start mt-4">
            <Col className="col"><h3>ADD RECIPE</h3></Col>
        </Row>      
        <Row className="row justify-content-md-center mt-4">
            <Col className="col-10">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>  
                    <Form.Group id="language" className="">    
                        <Form.Label>Language:</Form.Label>
                        <Form.Select ref={languageRef}>
                            <option value="lg_sk">Slovak</option>
                            <option value="lg_en">English</option>
                        </Form.Select>   
                    </Form.Group>                                   
                    <Form.Group controlId="formFile" className="mt-3">
                        <Form.Label>Picture:</Form.Label>
                        <Form.Control type="file" ref={fileRef} accept="image/*" onChange={onSelectFile}/>
                    </Form.Group>  
                    <ReactCrop crop={crop} aspect={1 / 1} onChange={c => setCrop(c)} onComplete={onCropComplete}>
                        <img id="cropimage" src={upImg} onLoad={onImageLoad} />
                    </ReactCrop>                                         
                    <Form.Group id="title" className="mt-3">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control type="text" size="lg" ref={titleRef} required />
                    </Form.Group>
                    <Form.Group id="recipe" className="mt-3">
                        <Form.Label>Recipe:</Form.Label>
                        <Form.Control as="textarea" ref={recipeRef} rows={5} />
                    </Form.Group>  
                    <Form.Group id="url" className="mt-3">
                        <Form.Label>Website URL:</Form.Label>
                        <Form.Control type="text" ref={urlRef} />
                    </Form.Group>                        
                    <hr className="mt-5" />
                    <Form.Group id="mynotes" className="mt-3">
                        <Form.Label>My notes:</Form.Label>
                        <Form.Control as="textarea" ref={mynotesRef} rows={3} />
                    </Form.Group>      
                    <Form.Group id="visibility" className="mt-3">    
                        <Form.Label>Visibility:</Form.Label>
                        <Form.Select ref={visibilityRef}>
                            <option value="public">Public - Everyone can see this recipe</option>
                            <option value="private">Private - Only I can see this recipe</option>
                        </Form.Select>   
                    </Form.Group>                                                                                                                
                    <Button disabled={loading} className="w-100 mt-3" type="submit">Save</Button>                          
                </Form>                
            </Col>           
        </Row>

        <div className="d-none">
            <div className="profile_pic__edit_main">
              <img
                style={{ width: 100, height: 100 }}
                src={upImg}
                className="profile__pic_edit"
                alt=""
              />
            </div>
        </div>
        <div className="d-none">
            <canvas
                ref={canvasRef}
                style={{
                border: '1px solid black',
                objectFit: 'contain',
                }}
            />
        </div>
    </Container>  
  )
}
