import React, { useRef, useState, useEffect } from 'react'
import { db, storage } from "../../firebase";
import { collection, query, where, or, getDoc, getDocs, serverTimestamp, doc, orderBy, limit, endBefore, arrayUnion, arrayRemove, updateDoc, limitToLast } from "firebase/firestore"
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap"
import { ChatSquareQuoteFill, ChevronCompactLeft, ChevronCompactRight } from 'react-bootstrap-icons';
import image from "../../assets/600x600.jpg";
import { Link } from 'react-router-dom'
import { getUserInfo, getRecipeTextData } from './HelperFunctions';

import { useAuth } from "../../contexts/AuthContext"

export default function UserSuggest() {
        const { currentUser } = useAuth();

        const [showRecipe, setShowRecipe] = useState(false)
        const ShowRecipe = () => setShowRecipe(true)
        const HideRecipe = () => setShowRecipe(false)

        const recipesRef = collection(db, "recipes");
        const [recipesList, setRecipesList] = useState([])
        const [currentRecipe, setCurrentRecipe] = useState(0)

        // constants for displayed food

        const [titleRef, setTitleRef] = useState('')  
        const [thumbnailUrl, setThumbnailUrl] = useState('')
        const [recipeNicknameRef, setRecipeNicknameRef] = useState('')
        const [likesRef, setLikesRef] = useState(0)
        const [lastHadRef, setLastHadRef] = useState('')

        // reference for if recipe is loading (so that user knows something is happening)
        const [loadingRef, setLoadingRef] = useState(false);

        const [liked, setLiked] = useState(false)

        // not to be confused with recipesref, this is the recipe which is displayed
        const [recipeRef, setRecipeRef] = useState('')

        // and this makes sure that we don't show the same recipe twice
        const [permaRecipesShownArray, setPermaRecipesShownArray] = useState([])

        // I guess we should also have the total recipe count so that we can not show the right arrow once all recipes are loaded
        // set to a random high number, changed once we go through all the possible recipes
        const [permaRecipeListLength, setPermaRecipeListLength] = useState(999999999)

        // what recipe group should the recipes be picked from: public or user?
        const [recipeGroupRef, setRecipeGroupRef] = useState('private');

        // get the users language
        const [language, setLanguage] = useState("lg_en");

        const handleGroupRadioChange = (event) => {
            setRecipesList([]);
            setPermaRecipesShownArray([]);
            setRecipeGroupRef(event.target.value);
        }

        async function displayRecipe() {
            if(recipesList.length == 0) {return 0}

            const r = recipesList[currentRecipe];

            setTitleRef(getRecipeTextData(r, language).title);
            setThumbnailUrl(r.thumbnailUrl);
            setRecipeRef(getRecipeTextData(r, language).recipe);
            setLikesRef(r.likedBy.length);

            setLiked(r.likedBy.includes(currentUser.uid));

            // get the last time user had recipe
            try {
                if (r.lastHad.seconds == 0) {
                    setLastHadRef("You've never had this food")
                } else {
                    let timeSince = ((Date.now() - (r.lastHad.seconds * 1000)) / (1000 * 3600 * 24)).toFixed(0)
                    if (timeSince == "0") {
                        setLastHadRef("You had this food today! ")
                    } else {
                        setLastHadRef("You had this food " + timeSince + " days ago.")
                    }
                }
            } catch (error) {
                console.error(error);
            }

            // setRecipeNicknameRef(r.userNickname);

            // ideally we should show the current nickname of a user so:
            try {
                const userData = await getUserInfo(r.userId);
                setRecipeNicknameRef(userData.userNickname);
            } catch (error) {
                console.error(error);
            }
        }

        async function scrollRecipe(index) {
            if(currentRecipe + index == recipesList.length){
                setLoadingRef(true);
                let newR = await getRecipes(recipeGroupRef);
                if(newR){
                    setCurrentRecipe(currentRecipe + index);
                }
                return 0;
            }
            if(currentRecipe + index < 0) return 0;

            setCurrentRecipe(currentRecipe + index);
        }

        async function LikeRecipe() {
            try {
                var tmpDocRef = doc(db, "recipes", recipesList[currentRecipe].id);
                updateDoc(tmpDocRef, 
                liked ? 
                    { likedBy: arrayRemove(currentUser.uid)}
                    :
                    { likedBy: arrayUnion(currentUser.uid)}
                ).then(() => {
                    return getDoc(tmpDocRef);
                }).then((docSnap) => {
                    const data = docSnap.data();
                    setLiked(data.likedBy.includes(currentUser.uid));
                    setLikesRef(data.likedBy.length);
                });
            } catch (error) {
                console.log(error);
            }
        }

        async function AddRecipeToHadList() {
            try {
                // ref to the user
                var tmpDocRef = doc(db, "users", currentUser.uid);
                updateDoc(tmpDocRef, {  
                    [`recentRecipes.${recipesList[currentRecipe].id}`]: serverTimestamp() 
                }) .then(() => {
                    return getDoc(tmpDocRef);
                }).then((docSnap) => {
                    //const data = docSnap.data();
                    //setLiked(data.likedBy.includes(currentUser.uid));
                    //setLikesRef(data.likedBy.length);
                    setLastHadRef("You had this food today!");
                    console.log("Added to list");
                });
            } catch (error) {
                console.log(error);
            }
        }
        
        async function getRecipes(group, amount = 3) {

            let recipesShownArray = [...permaRecipesShownArray];

            console.log("loading");

            //e.preventDefault()
    
            /*try {
                var tmpList = [];

                // generate a random integer which we will use to select the recipe
                var randomBegin = Math.floor(Math.random() * 10000000);

                if(startAt != 0) {randomBegin = startAt}

                console.log(randomBegin);

                if (group == "public") {

                    while (tmpList.length < 5) {

                        // so everything is now done in the query

                        let q = query(
                            recipesRef,
                            where("sharedWith", "array-contains", "*"),
                            where('languages.' + language + '.exists', "==", true),
                            where("randomValue", ">=", randomBegin),
                            orderBy("randomValue"),
                            limitToLast(10)
                        );
    
                        let querySnapshot = await getDocs(q);
                        
                        var tmptmpList = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

                        if (tmptmpList.length == 0) {
                            randomBegin = 0; 
                        } else {
                            randomBegin = tmptmpList[tmptmpList.length - 1].randomValue + 1;
                        }

                        console.log(tmpList);
                        tmpList = tmpList.concat(tmptmpList);
                    }

                    tmpList = tmpList.slice(0, 5);

                    // we have to filter out recipes in different languages
                    //tmpList = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter((recipe) => recipe.languages.includes(language));
                    
                    // we also want to filter out the user's own recipes
                    // tmpList = tmpList.filter((recipe) => recipe.userId != currentUser.uid);

                }  else { // private is default case to make sure q is always defined
                    //to get recipes owned by the user you have to do this:
                    const q = query(recipesRef, where("userId", "==", currentUser.uid));

                    const querySnapshot = await getDocs(q);

                    tmpList = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                }

                console.log(tmpList);

                setRecipesList(tmpList);
            } catch (error) {
                console.log(error);
            }*/

            // New approach uses the counters
            try {
                var tmpList = [];

                const recipeCount = (await getDoc(doc(db, "counters", "recipe_counter"))).data().value + 1;

                const userData = await getUserInfo(currentUser.uid);

                if (group == "public") {

                    while (tmpList.length < amount && recipesShownArray.length < recipeCount) {

                        // the way we imagine this is that let's say we can generate numbers 1-10 but don't want to include 5
                        // thus, we generate a number from 1-9 and add 1 if it's larger or equal to 5
                        let randomValue = Math.floor(Math.random() * (recipeCount - recipesShownArray.length));
                        recipesShownArray.sort((a, b) => a - b)

                        for(let i = 0; i < recipesShownArray.length; i++){
                            if(randomValue >= recipesShownArray[i]){
                                randomValue++;
                            }else{
                                break
                            }
                        }

                        console.log(randomValue, recipeCount);

                        // so everything is now done in the query

                        let q = query(
                            recipesRef,
                            where("sharedWith", "array-contains", "*"),
                            where('languages.' + language + '.exists', "==", true),
                            where("randomValue", ">=", randomValue),
                            orderBy("randomValue", "asc"),
                            limit(1)
                        );
    
                        let querySnapshot = await getDocs(q);

                        // this condition does occasionally get called, but hopefully it's not too much of a problem lol
                        // basically if there's no recipes with the language higher up from the index, you have to skip
                        if(querySnapshot.docs.length == 0){

                            let tmpIndexList = [];
                            for (let i = randomValue; i < recipeCount; i++) {
                                tmpIndexList.push(i);
                            }
    
                            recipesShownArray = recipesShownArray.concat(tmpIndexList)
                            recipesShownArray = recipesShownArray.filter((item, index) => recipesShownArray.indexOf(item) === index);
                            
                            console.log("None Found");
                            console.log(recipesShownArray);
                            continue;
                        }

                        let endIndex = querySnapshot.docs[0].data().randomValue;

                        console.log(randomValue, endIndex);

                        
                        // so now we have to take all the numbers from the last occurance up to this one and add those to the ignore list
                        let q2 = query(
                            recipesRef,
                            where("sharedWith", "array-contains", "*"),
                            where('languages.' + language + '.exists', "==", true),
                            where("randomValue", "<", endIndex),
                            orderBy("randomValue", "desc"),
                            limit(1)
                        );

                        let querySnapshot2 = await getDocs(q2);

                        // so the lead index has to be a minimum of 0, but sometime it'll be undefined, ergo we have to set it manually
                        let leadIndex = querySnapshot2.docs.length > 0 ? querySnapshot2.docs[0].data().randomValue : -1;

                        // add leadIndex up to endIndex to the ignore list
                        let tmpIndexList = [];
                        for (let i = leadIndex + 1; i <= endIndex; i++) {
                            tmpIndexList.push(i);
                        }

                        console.log("Recipe index is", endIndex, "Therefore we're deleting from", leadIndex, "To get", tmpIndexList);

                        // set the shown recipes list to include all the numbers between
                        // remove duplicates as well

                        recipesShownArray = recipesShownArray.concat(tmpIndexList)
                        recipesShownArray = recipesShownArray.filter((item, index) => recipesShownArray.indexOf(item) === index);

                        // We add the recipe to the tmpList
                        
                        tmpList = tmpList.concat(querySnapshot.docs.map((doc) => 
                        ({ 
                            ...doc.data(), 
                            id: doc.id, 
                            lastHad: Object.hasOwn(userData.recentRecipes, doc.id) ? userData.recentRecipes[doc.id] : {seconds: 0}
                        })));
                        
                    }

                    if (recipesShownArray.length >= recipeCount) {
                        setPermaRecipeListLength(recipesList.length);
                        console.log("LISTLEN", recipesList.length);
                    }

                }  else { 
                    // private is default case to make sure q is always defined
                    //to get recipes owned by the user you have to do this:
                    const q = query(recipesRef, where("userId", "==", currentUser.uid));

                    const querySnapshot = await getDocs(q);

                    tmpList = querySnapshot.docs.map((doc) => 
                    ({ 
                        ...doc.data(), 
                        id: doc.id, 
                        lastHad: Object.hasOwn(userData.recentRecipes, doc.id) ? userData.recentRecipes[doc.id] : {seconds: 0}
                    }));

                    // here we're going to have to somehow shuffle the array or something

                    // first off let's order the ones that we had recently to the back

                    tmpList.sort(function(a,b){return a.lastHad - b.lastHad})
                    
                    // there's two factors we have to consider now, when doing the random shuffle
                    // 1. two recipes should be more likely to be switched if their last eaten dates are close to each other
                    // 2. a recipe should be less likely to be pushed forward the more recently it was eaten

                    // 1. can be solved using this, the higher the number, the less likley the recipes should be shuffled
                    // If you had a recipe 40 days ago and another one 5 days ago, you should have a higher chance of first being recommended the one 40 days ago
                    // For now we're going to assume a 10% bias for every 4 days 
                    // console.log((tmpList[2].lastHad.seconds - tmpList[1].lastHad.seconds) / 86400);

                    // 2. can be solved like this, the higher the number, the less likely the recipe is to be moved forward
                    // let's say that a recipe should be pushed forward if the user had it more than 50 days ago
                    // console.log((Date.now() / 1000 - tmpList[2].lastHad.seconds) / 86400);

                    const currentTime = Date.now() / 1000;


                    tmpList.sort(function(a,b){
                        /*console.log(a.lastHad.seconds, b.lastHad.seconds, 
                            -1 + // always bias the shuffle to retain the current order
                            Math.random() + // random number between 0 and 1, so without any modifications, there's a 50% chance order stays the same
                            - (a.lastHad.seconds > 0 && b.lastHad.seconds > 0 ? (((b.lastHad.seconds - a.lastHad.seconds) / 86400) / 40) : 0) + // recipes are more likely to stay the same if there's a big gap between them
                            (b.lastHad.seconds > 0 ? ((currentTime - b.lastHad.seconds) / 86400) / 50 : 0) // the longer ago you had the recipe, the more likely it is to be shuffled
                        )*/
                        return (
                            -0.5 + // always bias the shuffle to retain the current order
                            Math.random() + // random number between 0 and 1, so without any modifications, there's a 50% chance order stays the same
                            - Math.abs(a.lastHad.seconds > 0 && b.lastHad.seconds > 0 ? (((b.lastHad.seconds - a.lastHad.seconds) / 86400) / 40) : -0.25) + // recipes are more likely to stay the same if there's a big gap between them
                            (b.lastHad.seconds > 0 ? ((currentTime - b.lastHad.seconds) / 86400) / 50 : -0.25) // the longer ago you had the recipe, the more likely it is to be shuffled
                        )
                    });

                }

                console.log(recipesShownArray);

                setPermaRecipesShownArray(recipesShownArray);
                setRecipesList(recipesList.concat(tmpList));

                console.log("loaded");
                setLoadingRef(false);

                if(tmpList.length > 0) {
                    return 1;
                }
                return 0;
            } catch (error) {
                setLoadingRef(false);
                console.log(error);
            }
        }
    
        useEffect(() => {
            displayRecipe();
        }, [recipesList, currentRecipe]);

        useEffect(() => {
            console.log(recipeGroupRef);
            setCurrentRecipe(0); // so we don't go out of bounds in an array
            setLoadingRef(true);
            getRecipes(recipeGroupRef);
        }, [recipeGroupRef]);

        useEffect(() => {
            async function fetchData() {
                try {
                    const userData = await getUserInfo(currentUser.uid);
                    setLanguage(userData.defaultLanguage);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchData();
        }, []);

  return (
    <Container>
        <Row className="row align-items-start mt-4">
            <Col className="col"><h3>SUGGESTIONS FOR TODAY&apos;s MEAL</h3></Col>
        </Row>
        <Row className="row align-items-start">
            <Col className="col-auto">Recipes from:</Col>
            <Col className="col-auto">
                <Form>
                    <Form.Group>
                        <Form.Check value={'public'} inline type="radio" name="group1" label="Other&apos;s" onChange={handleGroupRadioChange}/>
                        <Form.Check value={'private'} inline type="radio" name="group1" label="My own" defaultChecked onChange={handleGroupRadioChange}/>
                    </Form.Group>               
                </Form>
            </Col>                                    
        </Row>

        <Row className='row align-items-center mt-4'>
        {
            loadingRef &&
            <p style={{width: 100 + '%', textAlign: 'center'}}>Loading...</p>
        } 
        </Row>

        {recipesList.length > 0 &&
        <Row className="row align-items-center mt-4">
            <Col className="col-1 px-0">
                {
                    currentRecipe > 0 && 
                    <h1><ChevronCompactLeft className="float-end" onClick={() => scrollRecipe(-1)}/></h1>
                }
            </Col>
            <Col className="col-10">  
                <Card>
                    <Card.Img variant="top" src={thumbnailUrl} />
                    <Card.Body>
                        <Card.Title>{titleRef}</Card.Title>
                        <div className="d-flex justify-content-between">
                                <div>{likesRef} likes. {lastHadRef}</div>
                                {recipeGroupRef !== "private" && <div>From: {recipeNicknameRef}</div>}
                            </div>
                            <div className="border-bottom pb-3">
                                <div className={!showRecipe ? 'd-block' : 'd-none'}>
                                    <Link to="#" className="text-secondary" onClick={ShowRecipe}>Show recipe</Link>
                                </div>
                                <div className={showRecipe ? 'd-block' : 'd-none'}>
                                    {recipeRef}
                                    <div><Link to="#" className="text-secondary" onClick={HideRecipe}>Hide recipe</Link></div>
                                </div>
                            </div>
                        {
                            recipeGroupRef !== "private" &&
                            <div className="float-start mt-3"><Button variant={liked == false ? "primary" : "secondary"} onClick={LikeRecipe}>{liked == false ? "LIKE" : "DISLIKE"}</Button></div>
                        }
                        <div className="float-end mt-3"><Button variant="primary" onClick={AddRecipeToHadList}>I WILL HAVE THIS</Button></div>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="col-1 px-0">
                {
                    (currentRecipe < recipesList.length - 1 || (recipeGroupRef == "public" && currentRecipe < permaRecipeListLength)) &&
                    <h1><ChevronCompactRight className="float-start" onClick={() => scrollRecipe(1)}/></h1>
                }
            </Col>
        </Row>
        }
        {recipesList.length == 0 &&
        <div style={{height: 200 + 'px', lineHeight: 200 + 'px', textAlign: 'center'}}>
            <p>Just empty</p>
        </div>
        }
    </Container>  
  )
}
