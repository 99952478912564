import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from "../contexts/AuthContext"

export default function PrivateRoute({ redirectPath = '/', children }) {
    const { currentUser } = useAuth()

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    if ( !currentUser)
        return <Navigate to={redirectPath} replace />;

    if (currentUser.emailVerified == null)
        return <Navigate to={redirectPath} replace />;

    if ( redirectPath == '/verify-email' && !currentUser.emailVerified )
        return <Navigate to={redirectPath} replace />;

    return children ? children : <Outlet />              
}
