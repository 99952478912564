import { db, storage } from "../../firebase";
import { getDoc, doc } from "firebase/firestore"

export function getRecipeTextData(recipe, preferedLanguage) {
    // ideally return the data in the prefered language
    if (Object.hasOwn(recipe.languages, preferedLanguage)) return recipe.languages[preferedLanguage];
    // if said data doesn't exist, return it in english
    if (Object.hasOwn(recipe.languages, 'lg_en')) return recipe.languages['lg_en'];
    // if the recipe also doesn't have english data, return the data in the first language that the recipe has
    return recipe.languages[Object.keys(recipe.languages)[0]];
}

export async function getUserInfo(userId) {
    const userDocRef = doc(db, 'users', userId);

    const userDocSnap = await getDoc(userDocRef);

    let userData = userDocSnap.data();

    return userData;
}