import React, { useContext, useEffect, useState } from 'react'
import { db, storage } from "../../firebase";
import { collection, query, where, getDocs, getDoc, doc, orderBy, limit } from "firebase/firestore"
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap"
import { Link } from 'react-router-dom'
import { useAuth } from "../../contexts/AuthContext"
import { ExpandedRecipeCard } from './UserEditRecipe';
import { getRecipeTextData, getUserInfo } from './HelperFunctions';

export default function UserRecipes() {
    const recipesRef = collection(db, "recipes");

    const [recipesList, setRecipesList] = useState([]);
    const { currentUser } = useAuth();

    // all of this relates to expanding an editable recipe when you click on one
    const [expandedRecipe, setExpandedRecipe] = useState(null);
    const handleRecipeClick = (recipe) => {
        expandedRecipe == recipe ? setExpandedRecipe(null) : setExpandedRecipe(recipe);
    }

    const handleCloseClick = () => {
        setExpandedRecipe(null);
    }

    // get the users language
    const [language, setLanguage] = useState("lg_en");
    
    async function getRecipes() {      
        //e.preventDefault()

        try {
            // first I actually want to get the user's default language
            const userData = getUserInfo(currentUser.uid);
            setLanguage(userData.defaultLanguage);

            // Create a query against the collection.
            // You may need to create index first!
            const q = query(recipesRef, where("userId", "==", currentUser.uid));
    
            const querySnapshot = await getDocs(q);
            
            console.log(querySnapshot);

            setRecipesList(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            console.log(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))     
        } catch {
            console.log("failed to read recipes")
        }
    }

    useEffect(() => {
        getRecipes();
        }, []);

  return (
    <Container>
        <Row className="row align-items-start mt-4">
            <Col className="col"><h3>MY RECIPES</h3></Col>
        </Row>  
        {recipesList.map( (recipe) => {
            return (
                <Card className="flex-row mb-2" key={recipe.id}>
                    <div className="card-img-container">
                        <Card.Img src={recipe.thumbnailUrl}  className="card-img"/>
                    </div>
                    <Card.Body>
                        <Card.Title  onClick={() => handleRecipeClick(recipe)}>{getRecipeTextData(recipe, language).title}</Card.Title>
                        <Card.Text>
                        {((Date.now() - (getRecipeTextData(recipe, language).createDate.seconds * 1000)) / (1000 * 3600 * 24)).toFixed(0)} days ago
                        </Card.Text>

                        <div className={`card-bottom ${expandedRecipe === recipe ? 'opened' : ''}`}>
                            <ExpandedRecipeCard recipe={recipe} recipeId={recipe.id} language={language} onClose={handleCloseClick} />
                        </div>  
                    </Card.Body>
                </Card>
            )
        })}
    </Container>
  )
}
