import React from 'react'
import { Container } from "react-bootstrap"
import { Link } from 'react-router-dom'

export default function Logout() {
  return (
    <Container>
      Logged out. Go back to <Link to="/">Home page</Link> 
    </Container>
  )
}
