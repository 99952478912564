import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from 'react-router-dom'

import { db } from "../firebase";
import { doc, collection, getDocs, setDoc, query, where } from "firebase/firestore"

export default function Signup() {
    const userNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirm = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useNavigate()

    const usersRef = collection(db, "users");

    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirm.current.value) {
            return setError('Password do not match')
        }

        try {
            setError('')
            setLoading(true)

            const set_username = userNameRef.current.value;

            // regex username check

            const usernameRegex = /^[a-zA-Z0-9_.-]+$/;
            if (!usernameRegex.test(set_username)) {
                throw 'Invalid Username';
            }

            // check if username doesn't already exist
            const q = query(
                usersRef,
                where("userNickname", "==", set_username)
            );

            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length > 0) {
                throw 'Username already exists';
            }

            const user = await signup(emailRef.current.value, passwordRef.current.value)

            // we also have to add the user into the users database 
            // we actually give the user doc the same id as the user id, which makes it easier to access
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, {
                userNickname: set_username,
                userEmail: emailRef.current.value,
                recentRecipes: {},
                defaultLanguage: "lg_en"
            });
            
            history("/");   
        } catch (errorMsg) {
            setError(errorMsg);
        }
        setLoading(false)

    }
    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Sign Up</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" ref={userNameRef} required />
                        </Form.Group>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" ref={passwordConfirm} required />
                        </Form.Group>      
                        <Button disabled={loading} className="w-100 mt-3" type="submit">Sign Up</Button>                          
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login">Login</Link>
            </div>
        </>
    ) 
}
