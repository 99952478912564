import React, { useContext, useEffect, useState } from 'react'
import { db, storage } from "../firebase";
import { collection, query, where, getDocs, doc, orderBy, startAt, endAt } from "firebase/firestore"
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap"
import { Link } from 'react-router-dom'

export default function Suggest() {
    const recipesRef = collection(db, "recipes");
    const [recipesList, setRecipesList] = useState([])
    
    async function getRecipes() {      
        //e.preventDefault()

        try {
            // Create a query against the collection.
            //const q = query(recipesRef, where("userId", "==", "v68nCALp5OgVxGns8v5FoUp8onw2"), orderBy("lastUpdated", "desc"));
            const q = query(recipesRef, where("visibility", "==", "public"));
    
            const querySnapshot = await getDocs(q);

            // For pagination, read this: https://cloud.google.com/firestore/docs/query-data/query-cursors#paginate_a_query

            setRecipesList(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            console.log(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))     
        } catch {
            console.log("failed to read recipes")
        }
    }

    useEffect(() => {
        getRecipes();
        }, []);

  return (
    <Container>
        <Row className="row align-items-start mt-4">
            <Col className="col"><h3>MY RECIPES</h3></Col>
        </Row>  
        {recipesList.map( (recipe) => {
            return (
                <Card className="flex-row mb-2" key={recipe.id}>
                    <Card.Img style={{ width: '25%'}} src={recipe.lg_sk.thumbnailUrl} />
                    <Card.Body>
                        <Card.Title><Link href="#">{recipe.lg_sk.title}</Link></Card.Title>
                        <Card.Text>
                        {((Date.now() - (recipe.lg_sk.createDate.seconds * 1000)) / (1000 * 3600 * 24)).toFixed(0)} days ago
                        </Card.Text>
                    </Card.Body>
                </Card>
            )
        })}
    </Container>
  )
}
