import React from 'react'
import { Button } from "react-bootstrap"

export default function Home() {
  return (
    <div>
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center" style={{ marginTop: "50px" }}>Created to answer everyday&lsquo;s question</div>      
      </div>        
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center"><h1>What's for dinner?</h1></div>      
      </div>   
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center" style={{ maxWidth: "400px" }}>Also, remembers all the recipes you and your family members like. So it&apos;s easier to choose what to eat today.</div>      
      </div>    
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center">Enjoy!</div>      
      </div>            
      <div className="d-flex justify-content-center mt-4">
        <div style={{ marginTop: "30px" }}><Button className="me-2 btn btn-primary" href="/user/suggest">START</Button></div>
      </div>   
    </div>
  )
}
